import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import {useShoppingCart} from 'use-shopping-cart'

import styles from '../styles/index_styles';
import Layout from '../components/layout/index';
import TipTopIcon from '../components/tiptopicon/index';
import Seo from '../components/seo';

export default function Home() {
	const {clearCart, cartCount} = useShoppingCart()

	useEffect(() => {
		if (cartCount > 0) {
			clearCart();
		}
	}, [cartCount, clearCart]);

	return (
		<Layout>
			<Seo />
			<main css={styles.thanksContainer}>
				<Link style={{ textDecoration: 'none' }} to="/">
					<TipTopIcon size={230} />
					<h1 css={styles.logoTitle}>TipTop</h1>
				</Link>

				<h1 css={styles.thanks}>Thanks for Buying!</h1>
			</main>
		</Layout>
	);
}
